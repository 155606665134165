// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start()
require("@rails/activestorage").start();
require("channels");
require("jquery");
import "../controllers";

const _ = require("lodash");

window.questionCounter = 1;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).ready(function () {
  $.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  $.extend({
    distinct: function (anArray) {
      var result = [];
      $.each(anArray, function (i, v) {
        if ($.inArray(v, result) == -1) result.push(v);
      });
      return result;
    },
  });

  if ($(".alert-custom").length > 0) {
    setTimeout(function () {
      $(".alert-custom").fadeOut();
    }, 10000);
  }

  var scheduleMailCheckboxValue = false;
  $(".js-schedule-mail-checkbox").click(function () {
    if (scheduleMailCheckboxValue === false) {
      scheduleMailCheckboxValue = true;

      $(".js-toggle-schedule-picker-display-state").show("slow");
    } else {
      scheduleMailCheckboxValue = false;

      $(".js-toggle-schedule-picker-display-state").hide("slow");
    }
  });

  // $(".js-submit-project").click(function() {
  //   $(".js-receive-datetime").val($(".js-receive-datetime-input").val());
  //   if (scheduleMailCheckboxValue === true) {
  //     $(".js-scheduled_delay_start_date-value").val($(".js-scheduled_delay_start_date-input").val());
  //   }

  //   // Creates Departments when the project for if submitted, by going through the html and assing JSON to the params
  //   var depArray = []
  //   $(".js-created-departments-line-item-bx-master").children().each(function(i, obj) {

  //     var theDepTitle = obj.childNodes[1].childNodes[1].innerHTML
  //     var theDepHeadName = obj.childNodes[1].childNodes[3].innerHTML
  //     var theDepHeadEmail = obj.childNodes[1].childNodes[5].innerHTML

  //     var depHash = {};
  //     depHash['department_title'] = theDepTitle;
  //     depHash['department_head_name'] = theDepHeadName;
  //     depHash['department_head_email'] = theDepHeadEmail;
  //     depArray.push(depHash);
  //   });
  //   $(".js-departments-hash").val(JSON.stringify(depArray))

  //   // Creates Teams when the project for if submitted, by going through the html and assing JSON to the params
  //   var teamArray = []
  //   $(".js-created-teams-line-item-bx-master").children().each(function(i, obj) {

  //     var theTeamDepName = obj.childNodes[1].childNodes[1].innerHTML
  //     var theTeamTitle = obj.childNodes[1].childNodes[3].innerHTML
  //     var theTeamHeadName = obj.childNodes[1].childNodes[5].innerHTML
  //     var theTeamHeadEmail = obj.childNodes[1].childNodes[7].innerHTML

  //     var teamHash = {};
  //     teamHash['department_team_title'] = theTeamDepName;
  //     teamHash['team_title'] = theTeamTitle;
  //     teamHash['team_head_name'] = theTeamHeadName;
  //     teamHash['team_head_email'] = theTeamHeadEmail;
  //     teamArray.push(teamHash);
  //   });
  //   $(".js-teams-hash").val(JSON.stringify(teamArray))

  // })

  // Bulk upload checklist component start
  var resendInviteEmailsList = [];
  $(".checkbox3").click(function () {
    var emailtd = $(this).parent().siblings()[2];

    resendInviteEmailsList.push(emailtd.innerText);
  });

  $(".js-resend-user-invites").click(function () {
    $(".js-recieve-invite_email").val($.distinct(resendInviteEmailsList));
  });

  function incrementQuestionCounter(event) {
    if (
      event.target.getAttribute("data-section") == ".js-normative-questions"
    ) {
      return;
    }
    if (event.target.getAttribute("data-section") == ".js-saas-questions") {
      return;
    }
    window.questionCounter++;
    var allNumberCircles = document.getElementsByClassName("js-number-circle");
    for (var i = 0; i < allNumberCircles.length; i++) {
      allNumberCircles[i].innerText = window.questionCounter;
    }
  }

  $(".js-advance-btn").click(function (e) {
    var sectionToGo = $(this).data("number");
    $(".js-questions").hide();
    $(`.js-question-set-${sectionToGo}`).show();
    // Change height:100% into auto
    $("body").css("height", "auto");
    // Successfully scroll back to top
    $("body").scrollTop(0);
    // Remove javascript added styles
    $("body").css("height", "");
    incrementQuestionCounter(e);
  });

  $(".js-advance-section-btn").click(function (e) {
    var nextSection = $(this).data("section");
    var previousQuestionSet = $(this).data("number");
    $(".js-question-area").hide();
    $(`.js-question-set-${previousQuestionSet}`).hide();
    $(nextSection).show();

    if (
      nextSection == ".js-ipsative-questions-1" ||
      nextSection == ".js-ipsative-questions-2"
    ) {
      sectionHTML = document.querySelectorAll(`${nextSection}.drag-area`);
      sectionHTML[0].style.display = "flex";
    }

    if (nextSection == ".js-normative-questions") {
      $(".js-section-heading").show();
    }

    if (nextSection == ".js-finish") {
      $(".js-section-heading").hide();
    }

    if (nextSection == ".js-organisation-questions") {
      $(".js-organisation-questions .js-question-set-0").show();
      $(".js-organisation-questions-custom")
        .children()
        .eq(0)
        .find(".js-rating-question-action-bx-0")
        .hide();
      if (
        $(".js-organisation-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx").length ===
        $(".js-organisation-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx")
          .find(".active").length
      ) {
        $(`.js-rating-question-action-bx-0`).show();
      }
    }

    if (nextSection == ".js-additional-questions") {
      $(".js-additional-questions .js-question-set-0").show();
      $(".js-section-heading").show();
      $(".js-additional-questions-custom")
        .children()
        .eq(0)
        .find(".js-rating-question-action-bx-0")
        .hide();
      if (
        $(".js-additional-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx").length ===
        $(".js-additional-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx")
          .find(".active").length
      ) {
        $(`.js-rating-question-action-bx-0`).show();
      }
    }

    if (nextSection == ".js-saas-questions") {
      $(".js-saas-questions .js-question-set-0").show();
      // $(".js-saas-questions .js-question-set-1").hide();
      $(".js-section-heading").show();
      $(".js-saas-questions-custom")
        .children()
        .eq(0)
        .find(".js-rating-question-action-bx-0")
        .hide();
      if (
        $(".js-saas-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx").length ===
        $(".js-saas-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx")
          .find(".active").length
      ) {
        $(`.js-rating-question-action-bx-0`).show();
      }
    }

    if (nextSection == ".js-its-select-questions") {
      $(".js-its-select-questions .js-question-set-0").show();
      $(".js-its-select-questions-custom")
        .children()
        .eq(0)
        .find(".js-rating-question-action-bx-0")
        .hide();
      if (
        $(".js-its-select-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx").length ===
        $(".js-its-select-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx")
          .find(".active").length
      ) {
        $(`.js-rating-question-action-bx-0`).show();
      }
    }

    if (nextSection == ".js-custom-questions") {
      $(".js-custom-questions .js-question-set-0").show();
      $(".js-custom-questions-custom")
        .children()
        .eq(0)
        .find(".js-rating-question-action-bx-0")
        .hide();
      if (
        $(".js-custom-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx").length ===
        $(".js-custom-questions-custom")
          .children()
          .eq(0)
          .find(".individual-question-bx")
          .find(".active").length
      ) {
        $(`.js-rating-question-action-bx-0`).show();
      }
    }

    // Change height:100% into auto
    $("body").css("height", "auto");
    // Successfully scroll back to top
    $("body").scrollTop(0);
    // Remove javascript added styles
    $("body").css("height", "");

    incrementQuestionCounter(e);
  });

  $(document).keyup(function (event) {
    if (event.which === 13) {
      $(".js-advance-btn-enter").each(function (index) {
        if ($(this).width() >= 1) {
          $(this).click();
        }
      });
    }
  });

  $(".js-ips-empty-blocks").hover(
    function () {
      $(this).hide();
    },
    function () {
      $(this).show();
    }
  );

  $(".droppable-area1, .droppable-area2")
    .sortable({
      items: ".draggable-item",
      connectWith: ".connected-sortable",
      stack: ".connected-sortable ul",
      placeholder: "ipsative-drag-empty",
      beforeStop: function (ev, ui) {
        var chosen = $(".droppable-area1 .ipsative-drag").length - 1;
        if (chosen >= 5) {
          $(this).sortable("cancel");
        }
      },
      update: function (evt, ui) {
        var second_array = [];
        $(".js-ranking-number").hide();

        // First add the top 5
        $(".droppable-area1 .ipsative-drag").each(function (index) {
          $(this).data("id", index);
          $(this)
            .find(".js-ranking-number")
            .html(index + 1);
          $(this).find(".js-ranking-number").show();
          second_array.push($(this).attr("value"));
        });

        // Add the next in the bottom list
        $(".droppable-area2 .ipsative-drag").each(function (index) {
          second_array.push($(this).attr("value"));
        });

        var movedDivId = ui.item[0].getAttribute("value");
        var userProjectId = $(".js-user-project-id").attr("value");
        var projectId = $(".js-user-project-id").attr("project_id");
        var theQuestionType =
          this.getElementsByClassName("ipsative-drag")[0].getAttribute(
            "data-question_type"
          );
        var thisObjCurrentIndex = $(".ipsative-drag").attr(
          "index_in_question_type"
        );
        var answerCount = $(".ipsative-drag").data("answercount");
        var questionCount = $(".ipsative-drag").data("questioncount");

        $.ajax({
          type: "GET",
          url:
            "/change_index_in_question_type?user_project_id=" +
            userProjectId +
            "&question_type=" +
            theQuestionType +
            "&index_in_question_type=" +
            thisObjCurrentIndex +
            "&second_array=" +
            second_array +
            "&project_id=" +
            projectId +
            "&question_id=" +
            movedDivId +
            "&answer_count=" +
            answerCount +
            "&question_count=" +
            questionCount,
          data: $(this).serialize(),
          success: function (response) {
            var percent = parseInt(response.the_percentage.toFixed(2));

            $(".js-percentage-progress").css({ width: `${percent}%` });
            $(".js-percentage-progress").attr("aria-valuenow", `${percent}`);
          },
        });

        var chosen = $(".droppable-area1 .ipsative-drag").length;
        if (chosen == 5) {
          $(".js-ipsative-1-next-section").show();
        } else {
          $(".js-ipsative-1-next-section").hide();
        }
      },
    })
    .disableSelection();

  $(".droppable-area1-second, .droppable-area2-second")
    .sortable({
      items: ".draggable-item-second",
      connectWith: ".connected-sortable-second",
      stack: ".connected-sortable-second ul",
      placeholder: "ipsative-drag-empty",
      beforeStop: function (ev, ui) {
        var chosen =
          $(".droppable-area1-second .ipsative-drag-second").length - 1;
        if (chosen >= 5) {
          $(this).sortable("cancel");
        }
      },
      update: function (evt, ui) {
        var second_array = [];
        $(".js-ranking-number").hide();

        // First add the top 5
        $(".droppable-area1-second .ipsative-drag-second").each(function (
          index
        ) {
          $(this).data("id", index);
          $(this)
            .find(".js-ranking-number")
            .html(index + 1);
          $(this).find(".js-ranking-number").show();
          second_array.push($(this).attr("value"));
        });

        // Add the next in the bottom list
        $(".droppable-area2-second .ipsative-drag-second").each(function (
          index
        ) {
          second_array.push($(this).attr("value"));
        });

        var movedDivId = ui.item[0].getAttribute("value");
        var userProjectId = $(".js-user-project-id").attr("value");
        var projectId = $(".js-user-project-id").attr("project_id");
        var theQuestionType = this.getElementsByClassName(
          "ipsative-drag-second"
        )[0].getAttribute("data-question_type");
        var thisObjCurrentIndex = $(".ipsative-drag-second").attr(
          "index_in_question_type"
        );
        var answerCount = $(".ipsative-drag-second").data("answercount");
        var questionCount = $(".ipsative-drag-second").data("questioncount");

        $.ajax({
          type: "GET",
          url:
            "/change_index_in_question_type?user_project_id=" +
            userProjectId +
            "&question_type=" +
            theQuestionType +
            "&index_in_question_type=" +
            thisObjCurrentIndex +
            "&second_array=" +
            second_array +
            "&project_id=" +
            projectId +
            "&question_id=" +
            movedDivId +
            "&answer_count=" +
            answerCount +
            "&question_count=" +
            questionCount,
          data: $(this).serialize(),
          success: function (response) {
            var percent = parseInt(response.the_percentage.toFixed(2));

            $(".js-percentage-progress").css({ width: `${percent}%` });
            $(".js-percentage-progress").attr("aria-valuenow", `${percent}`);
          },
        });
        var chosen = $(".droppable-area1-second .ipsative-drag-second").length;
        if (chosen == 5) {
          $(".js-ipsative-2-next-section").show();
        } else {
          $(".js-ipsative-2-next-section").hide();
        }
      },
    })
    .disableSelection();

  if ($("#draggableMultipleQuestions").length > 0) {
    $("#draggableMultipleQuestions").sortable({
      update: function (evt, ui) {
        var second_array = [];

        [].forEach.call(
          this.getElementsByClassName("drag-line-item"),
          function (el, index) {
            el.setAttribute("data-id", index);
            $(".js-ipsative-ranking-number").hide();
            $(el)
              .find(".js-ipsative-ranking-number-dummy")
              .html(index + 1);

            second_array.push(el.getAttribute("value"));
          }
        );

        var movedDivId = ui.item[0].getAttribute("value");
        var userProjectId = $(".js-user-project-id").attr("value");
        var projectId = $(".js-user-project-id").attr("project_id");
        var theQuestionType =
          this.getElementsByClassName("drag-line-item")[0].getAttribute(
            "data-question_type"
          );
        var thisObjCurrentIndex = $(".drag-line-item").attr(
          "index_in_question_type"
        );

        $.ajax({
          type: "GET",
          url:
            "/change_index_in_question_type?user_project_id=" +
            userProjectId +
            "&question_type=" +
            theQuestionType +
            "&index_in_question_type=" +
            thisObjCurrentIndex +
            "&second_array=" +
            second_array +
            "&project_id=" +
            projectId +
            "&question_id=" +
            movedDivId,
          data: $(this).serialize(),
          success: function (response) {},
        });
      },
    });
  }
  if ($("#draggableMultipleQuestions2").length > 0) {
    $("#draggableMultipleQuestions2").sortable({
      update: function (evt, ui) {
        var second_array = [];

        [].forEach.call(
          this.getElementsByClassName("drag-line-item2"),
          function (el, index) {
            el.setAttribute("data-id", index);
            $(".js-ipsative-ranking-number2").hide();
            $(el)
              .find(".js-ipsative-ranking-number-dummy2")
              .html(index + 1);

            second_array.push(el.getAttribute("value"));
          }
        );

        var movedDivId = ui.item[0].getAttribute("value");
        var userProjectId = $(".js-user-project-id2").attr("value");
        var projectId = $(".js-user-project-id2").attr("project_id");
        var theQuestionType =
          this.getElementsByClassName("drag-line-item2")[0].getAttribute(
            "data-question_type"
          );
        var thisObjCurrentIndex = $(".drag-line-item2").attr(
          "index_in_question_type"
        );

        $.ajax({
          type: "GET",
          url:
            "/change_index_in_question_type?user_project_id=" +
            userProjectId +
            "&question_type=" +
            theQuestionType +
            "&index_in_question_type=" +
            thisObjCurrentIndex +
            "&second_array=" +
            second_array +
            "&project_id=" +
            projectId +
            "&question_id=" +
            movedDivId,
          data: $(this).serialize(),
          success: function (response) {},
        });
      },
    });

    $(
      "#draggableMultipleQuestions, #draggableMultipleQuestions2"
    ).disableSelection();

    var hideScaleMotivator = false;
    $(".js-hide-scales").click(function () {
      if (hideScaleMotivator === false) {
        hideScaleMotivator = true;
      } else {
        hideScaleMotivator = false;
      }
      $(".js-hide-scales-input").val(hideScaleMotivator);
    });
  }

  if ($(".u-rating-pill-rating").length > 0) {
    $(".u-rating-pill-rating").barrating("show", {
      theme: "bars-pill",
      initialRating: "1",
      showValues: true,
      showSelectedRating: false,
      allowEmpty: true,
      emptyValue: "-- no rating selected --",
      onSelect: function (value, text) {
        console.log("Selected rating: " + value);
      },
    });
  }

  function checkForNextSection(theParent) {
    theParent.addClass("js-question-answered");
    var canShowButton = 0;
    var noOfQuestionsInSet = theParent
      .parent()
      .find(".individual-question-bx").length;

    theParent
      .parent()
      .find(".individual-question-bx")
      .each(function (i, obj) {
        if (obj.classList.contains("js-question-answered")) {
          canShowButton = canShowButton + 1;
        }
      });
    if (canShowButton >= noOfQuestionsInSet) {
      var correctBatchActionbx = theParent.parent().data("questionbatchset");
      $(`.js-rating-question-action-bx-${correctBatchActionbx}`).show();
    }
  }

  $(".js-rating-bx-item").click(function () {
    checkForNextSection($(this).parent().parent());
    var rating = $(this).data("value");
    var questionId = $(this).parent().data("questionid");
    var projectId = $(this).parent().data("projectid");
    var userId = $(this).parent().data("userid");
    var answerCount = $(this).parent().data("answercount");
    var questionCount = $(this).parent().data("questioncount");

    // $(this).parent().siblings().eq(0).html(`rating: ${rating}`);
    $(this).parent().children().removeClass("active");
    $(this).addClass("active");

    $.ajax({
      type: "POST",
      url: "/create_or_update_answer",
      data: {
        answer: {
          rating: rating,
          question_id: questionId,
          project_id: projectId,
          user_id: userId,
          answer_count: answerCount,
          question_count: questionCount,
        },
      },
      success: function (response) {
        var percent = parseInt(response.the_percentage.toFixed(2));

        $(".js-percentage-progress").css({ width: `${percent}%` });
        $(".js-percentage-progress").attr("aria-valuenow", `${percent}`);
        $(`.js-advance-${questionId}`).show();
      },
    });
  });

  $(".js-its-submit").click(function () {
    var projectId = $(this).data("projectid");
    var userId = $(this).data("userid");
    var questionCount = $(this).data("questioncount");
    var section = $(this).data("section");
    var checked_questions = $(this)
      .parent()
      .find(".js-checked-questions")
      .val();

    $.ajax({
      type: "POST",
      url: "/create_its_answer",
      data: {
        checked_questions: checked_questions,
        project_id: projectId,
        user_id: userId,
        question_count: questionCount,
        section: section,
      },
      success: function () {
        if (section == ".js-finish") {
          $("#project-completions-link")[0].click();
        } else {
          var percent = parseInt(response.the_percentage.toFixed(2));

          $(".js-percentage-progress").css({ width: `${percent}%` });
          $(".js-percentage-progress").attr("aria-valuenow", `${percent}`);
          $(`.js-advance-${questionId}`).show();
        }
      },
    });
  });

  $("#js-file-logo").change(function () {
    const file = this.files[0];
    console.log(file);

    if (file) {
      const reader = new FileReader(file);
      reader.addEventListener("load", function () {
        $("#js-file-logo-to-change").attr("src", this.result);
      });
      reader.readAsDataURL(file);
    } else {
      $("#js-file-logo-to-change").attr(
        "src",
        "/html/assets/images/dashboard/recent-user-1.png"
      );
    }
  });

  // Bulk upload checklist component finish
  if ($(".u-rating-pill").length > 0) {
    $(".u-rating-pill").barrating("show", {
      theme: "bars-pill",
      initialRating: "1",
      showValues: true,
      showSelectedRating: false,
      allowEmpty: true,
      emptyValue: "-- no rating selected --",
      onSelect: function (value, text) {
        console.log("Selected rating: " + value);
      },
    });
  }

  var addEvent = function (object, type, callback) {
    if (object == null || typeof object == "undefined") return;
    if (object.addEventListener) {
      object.addEventListener(type, callback, false);
    } else if (object.attachEvent) {
      object.attachEvent("on" + type, callback);
    } else {
      object["on" + type] = callback;
    }
  };

  var dragSection1 = document.querySelectorAll(
    ".js-ipsative-questions-1 .ipsative-drag"
  );
  var dragSection2 = document.querySelectorAll(
    ".js-ipsative-questions-2 .ipsative-drag-second"
  );

  dragSection1.forEach(function (btn) {
    btn.addEventListener("click", () => dragSection1Click(btn));
  });
  dragSection2.forEach(function (btn) {
    btn.addEventListener("click", () => dragSection2Click(btn));
  });

  const isTouchScreenDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  var questionaireText = function () {
    var draggableQuestionDescription = "";
    if (isTouchScreenDevice) {
      draggableQuestionDescription =
        "Clicking the items will add and remove them into and from the ranking section";
    } else {
      draggableQuestionDescription =
        "Drag and drop the items from the list into the ranking section.";
    }
    document.getElementById("js-drag-drop").innerText =
      draggableQuestionDescription;
  };

  addEvent(
    window,
    "resize",
    _.debounce(() => questionaireText(this), 200)
  );

  $(document).ready(function () {
    questionaireText();
  });

  var dragSection1Click = function (btn) {
    if (isTouchScreenDevice) {
      var rankedArea = document.querySelector(".droppable-area1");
      var unrakedArea = document.querySelector(".droppable-area2");

      //Removes Item from ranked section and moves to unranked section
      if (btn.parentNode == rankedArea) {
        var rankingSpan = btn.childNodes[1];
        rankingSpan.textContent = "";
        unrakedArea.appendChild(btn);
      }
      //Removes Item from unranked section and moves to ranked section
      else if (btn.parentNode == unrakedArea) {
        var chosen = $(".droppable-area1 .ipsative-drag").length;
        if (chosen >= 5) {
          return;
        }
        rankedArea.appendChild(btn);
      }
      var second_array = [];
      $(".js-ranking-number").hide();
      // First add the top 5
      $(".droppable-area1 .ipsative-drag").each(function (index) {
        $(this).data("id", index);
        $(this)
          .find(".js-ranking-number")
          .html(index + 1);
        $(this).find(".js-ranking-number").show();
        second_array.push($(this).attr("value"));
      });
      $(".droppable-area2 .ipsative-drag").each(function (index) {
        second_array.push($(this).attr("value"));
      });

      $(".js-ranking-number").show();

      var movedDivId = btn.getAttribute("value");
      var userProjectId = $(".js-user-project-id").attr("value");
      var projectId = $(".js-user-project-id").attr("project_id");
      var theQuestionType = btn.getAttribute("data-question_type");
      var thisObjCurrentIndex = $(".ipsative-drag").attr(
        "index_in_question_type"
      );
      var answerCount = $(".ipsative-drag").data("answercount");
      var questionCount = $(".ipsative-drag").data("questioncount");
      $.ajax({
        type: "GET",
        url:
          "/change_index_in_question_type?user_project_id=" +
          userProjectId +
          "&question_type=" +
          theQuestionType +
          "&index_in_question_type=" +
          thisObjCurrentIndex +
          "&second_array=" +
          second_array +
          "&project_id=" +
          projectId +
          "&question_id=" +
          movedDivId +
          "&answer_count=" +
          answerCount +
          "&question_count=" +
          questionCount,
        data: $(this).serialize(),
        success: function (response) {
          var percent = parseInt(response.the_percentage.toFixed(2));
          $(".js-percentage-progress").css({ width: `${percent}%` });
          $(".js-percentage-progress").attr("aria-valuenow", `${percent}`);
        },
      });
      var chosen = $(".droppable-area1 .ipsative-drag").length;
      if (chosen == 5) {
        $(".js-ipsative-1-next-section").show();
      } else {
        $(".js-ipsative-1-next-section").hide();
      }
    }
  };

  var dragSection2Click = function (btn) {
    if (isTouchScreenDevice) {
      var rankedArea = document.querySelector(".droppable-area1-second");
      var unrakedArea = document.querySelector(".droppable-area2-second");

      //Removes Item from ranked section and moves to unranked section
      if (btn.parentNode == rankedArea) {
        var rankingSpan = btn.childNodes[1];
        rankingSpan.textContent = "";
        unrakedArea.appendChild(btn);
      }
      //Removes Item from unranked section and moves to ranked section
      else if (btn.parentNode == unrakedArea) {
        var chosen = $(".droppable-area1-second .ipsative-drag-second").length;
        if (chosen >= 5) {
          return;
        }
        rankedArea.appendChild(btn);
      }
      var second_array = [];
      $(".js-ranking-number").hide();
      // First add the top 5
      $(".droppable-area1-second .ipsative-drag-second").each(function (index) {
        $(this).data("id", index);
        $(this)
          .find(".js-ranking-number")
          .html(index + 1);
        $(this).find(".js-ranking-number").show();
        second_array.push($(this).attr("value"));
      });
      $(".droppable-area2-second .ipsative-drag-second").each(function (index) {
        second_array.push($(this).attr("value"));
      });

      $(".js-ranking-number").show();

      var movedDivId = btn.getAttribute("value");
      var userProjectId = $(".js-user-project-id").attr("value");
      var projectId = $(".js-user-project-id").attr("project_id");
      var theQuestionType = btn.getAttribute("data-question_type");
      var thisObjCurrentIndex = $(".ipsative-drag-second").attr(
        "index_in_question_type"
      );
      var answerCount = $(".ipsative-drag-second").data("answercount");
      var questionCount = $(".ipsative-drag-second").data("questioncount");

      $.ajax({
        type: "GET",
        url:
          "/change_index_in_question_type?user_project_id=" +
          userProjectId +
          "&question_type=" +
          theQuestionType +
          "&index_in_question_type=" +
          thisObjCurrentIndex +
          "&second_array=" +
          second_array +
          "&project_id=" +
          projectId +
          "&question_id=" +
          movedDivId +
          "&answer_count=" +
          answerCount +
          "&question_count=" +
          questionCount,
        data: $(this).serialize(),
        success: function (response) {
          var percent = parseInt(response.the_percentage.toFixed(2));

          $(".js-percentage-progress").css({ width: `${percent}%` });
          $(".js-percentage-progress").attr("aria-valuenow", `${percent}`);
        },
      });

      var chosen = $(".droppable-area1-second .ipsative-drag-second").length;
      if (chosen == 5) {
        $(".js-ipsative-2-next-section").show();
      } else {
        $(".js-ipsative-2-next-section").hide();
      }
    }
  };
});

require("trix");
require("@rails/actiontext");
