import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    questionCount: Number,
    answeredCount: Number,
  };
  static targets = [
    "nextButton",
    "section",
    "ratingHeading",
    "improvementHeading",
    "openEndedHeading",
    "customHeading",
  ];

  initialize() {
    this.index = 0;
  }

  connect() {
    this.showSection(this.index);
    this.checkSectionComplete();
  }

  track({ detail: { div } }) {
    div.classList.add("question-answered");
    this.checkSectionComplete();
    this.showSectionHeading();
  }

  checkSectionComplete() {
    const answeredDivs =
      this.currentSection.querySelectorAll(".question-answered");
    const questionDivs = this.currentSection.querySelectorAll(".question-area");
    this.questionCountValue = questionDivs.length;
    this.answeredCountValue = answeredDivs.length;
    if (this.answeredCountValue == this.questionCountValue) {
      this.nextButtonTargets.forEach((button) => {
        if (button.dataset.pageButtonIndex == this.index) {
          button.style.display = "block";
        }
      });
    }
  }

  showSectionHeading() {
    switch (this.currentSection.dataset.pageSectionNameValue) {
      case "rating":
        this.ratingHeadingTarget.style.display = "block";
        this.improvementHeadingTarget.style.display = "none";
        this.customHeadingTarget.style.display = "none";
        this.openEndedHeadingTarget.style.display = "none";
        break;
      case "improvement":
        this.improvementHeadingTarget.style.display = "block";
        this.ratingHeadingTarget.style.display = "none";
        this.customHeadingTarget.style.display = "none";
        this.openEndedHeadingTarget.style.display = "none";
        break;
      case "openEnded":
        this.openEndedHeadingTarget.style.display = "block";
        this.improvementHeadingTarget.style.display = "none";
        this.ratingHeadingTarget.style.display = "none";
        this.customHeadingTarget.style.display = "none";
        break;
      case "custom":
        this.customHeadingTarget.style.display = "block";
        this.improvementHeadingTarget.style.display = "none";
        this.ratingHeadingTarget.style.display = "none";
        this.openEndedHeadingTarget.style.display = "none";
        break;
      default:
        this.ratingHeadingTarget.style.display = "block";
        this.improvementHeadingTarget.style.display = "none";
        this.customHeadingTarget.style.display = "none";
        this.openEndedHeadingTarget.style.display = "none";
    }
  }

  showNextSection() {
    const nextSectionIndex = parseInt(this.index, 10) + 1;

    const nextSection = this.sectionTargets.find(
      (section) => section.dataset.pageSectionIndex == nextSectionIndex
    );

    if (nextSection) {
      this.index++;
      this.showSection(nextSectionIndex);
    } else {
      $("#mini-pulse-complete-link")[0].click();
    }
  }

  showSection(sectionIndex) {
    this.sectionTargets.forEach((section) => {
      if (section.dataset.pageSectionIndex == sectionIndex) {
        section.style.display = "block";
        this.currentSection = section;
      } else {
        section.style.display = "none";
      }
    });
    this.checkSectionComplete();
    this.showSectionHeading();
  }
}
