import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [
    "questionsContainer",
    "questionsWrapper",
    "questionLabel",
    "questionOrderField",
    "addButton",
    "deleteBtn",
    "sortBtn",
  ];
  currentQuestionId = null;
  sortable = null;

  connect() {
    this.element.addEventListener("toggle-changed", (event) =>
      this.handleToggleChange(event)
    );
    this.initSortable();
  }

  disconnect() {
    this.element.removeEventListener("toggle-changed", (event) =>
      this.handleToggleChange(event)
    );
    if (this.sortable) {
      this.sortable.destroy();
    }
  }

  initSortable() {
    this.sortable = new Sortable(this.questionsContainerTarget, {
      handle: ".sort-handle",
      animation: 150,
      onEnd: (evt) => {
        this.updateQuestionOrder(evt);
      },
    });
  }

  updateQuestionOrder(evt) {
    this.updateQuestionLabels();
    this.updateQuestionOrderField();
  }

  handleToggleChange(event) {
    const { toggled, id } = event.detail;

    this.updateHiddenInput(toggled);

    if (id === "custom-question-toggle") {
      if (toggled) {
        this.questionsWrapperTarget.classList.remove("hidden");
      } else {
        this.questionsWrapperTarget.classList.add("hidden");
      }
    }
  }

  showDeleteConfirmation(event) {
    event.preventDefault();

    const questionId = event.currentTarget.dataset.questionId;
    this.currentQuestionId = questionId;

    this.modalElement.classList.remove("hidden");
  }

  hideDeleteConfirmation(event) {
    event.preventDefault();
    this.modalElement.classList.add("hidden");
  }

  deleteQuestion(event) {
    if (this.currentQuestionId) {
      const questionNode = this.questionsContainerTarget.querySelector(
        `[data-question-wrapper-id="${this.currentQuestionId}"]`
      );
      if (questionNode) {
        const destroyField = questionNode.querySelector(
          '[data-question-add-target="destroyField"]'
        );
        if (destroyField) {
          destroyField.value = "1";
        }
        questionNode.classList.add("hidden");
      }
      this.modalElement.classList.add("hidden");
    }
    this.hideDeleteConfirmation(event);
    this.updateQuestionLabels();
  }

  updateQuestionLabels() {
    let visibleQuestionCount = 1;
    this.questionLabelTargets.forEach((label) => {
      const questionDiv = label.closest(".custom-question-div");
      if (!questionDiv.classList.contains("hidden")) {
        label.textContent = `Question ${visibleQuestionCount}`;
        visibleQuestionCount++;
      }
    });
  }

  updateQuestionOrderField() {
    this.questionOrderFieldTargets.forEach((l, index) => {
      l.value = index + 1;
    });
  }

  get modalElement() {
    return document.getElementById("deleteConfirmationModal");
  }

  updateHiddenInput(toggled) {
    const hiddenInput = document.getElementById("toggleValue");
    hiddenInput.value = toggled ? "1" : "0";
  }

  addQuestion(event) {
    event.preventDefault();
    const newQuestionKey = new Date().getTime().toString().trimStart();

    const newQuestionHtml = `
      <div class="custom-question-div relative p-4 bg-white shadow-sm rounded-md flex flex-col justify-between items-start my-6 space-y-2" data-question-wrapper-id="${newQuestionKey}">
        <div class="flex flex-row items-center space-between absolute top-8 right-8 space-x-2">
          <button type="button" data-question-add-target="sortBtn" data-question-id="${newQuestionKey}" class="sort-handle">
            <svg viewBox="0 0 24 24" width="18" height="18" stroke="gray" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="5 9 2 12 5 15"></polyline><polyline points="9 5 12 2 15 5"></polyline><polyline points="15 19 12 22 9 19"></polyline><polyline points="19 9 22 12 19 15"></polyline><line x1="2" y1="12" x2="22" y2="12"></line><line x1="12" y1="2" x2="12" y2="22"></line></svg>
          </button>
          <button type="button" data-question-add-target="deleteBtn" data-question-id="${newQuestionKey}" data-action="click->question-add#showDeleteConfirmation">
            <svg viewBox="0 0 24 24" width="18" height="18" stroke="gray" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
          </button>
        </div>

        <div class="flex flex-row items-center justify-between">
          <p class="font-bold text-sm text-gray-900" data-question-add-target="questionLabel">Question ${
            this.questionCount + 1
          }</p>
        </div>

        <p class="text-xs">Select the type of question you want to add:</p>
        <div class="w-full" data-controller="question-type-select">
          <div class="grid grid-cols-2 w-1/3">
            <input type="hidden" name="mini_pulse[mini_pulse_questions_attributes][${newQuestionKey}][questionable_attributes][order]" value="${
      this.questionCount + 1
    }" data-question-add-target="questionOrderField" />
            <input type="hidden" name="mini_pulse[mini_pulse_questions_attributes][${newQuestionKey}][questionable_attributes][question_type]" value="text" data-question-type-select-target="input">
            <button type="button" class="p-2 text-xxs bg-[#c7b7d6] text-black flex items-center rounded justify-center mr-2" data-action="click->question-type-select#toggle" data-question-type-select-target="button" data-type="text">
              <img class="w-5 h-auto mr-4" src="/images/Open-ended_scale_icon.png">Open-Ended Text
            </button>
            <button type="button" class="p-2 text-xxs bg-white border border-gray-200 rounded text-black flex items-center justify-center" data-action="click->question-type-select#toggle" data-question-type-select-target="button" data-type="rating">
              <img class="w-5 h-auto mr-4" src="/images/Rating_scale_icon.png">Rating Scale (1-10)
            </button>
          </div>
          <p class="mt-6 text-xs">Type your question below:</p>
          <div class="mt-2 w-3/4">
          <textarea rows="4" placeholder="E.g. What three words would you use to describe our organisational culture?" class="resize-none block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple text-xs sm:leading-6" name="mini_pulse[mini_pulse_questions_attributes][${newQuestionKey}][questionable_attributes][content]" id="mini_pulse_mini_pulse_questions_attributes_${newQuestionKey}_questionable_attributes_content"></textarea>
          <div class="hidden mt-4 flex flex-col space-y-2" data-question-type-select-target="context">
            <p class="text-xs">1-10 rating scale:</p>
            <div class="w-full flex flex-row items-center space-between space-x-6 text-xxs">
              <span>1 = Very Strongly Disagree</span>
              <span>5 = Neutral</span>
              <span>10 = Very Strongly Agree</span>
            </div>
          </div>
        </div>
      </div>
    `;

    this.questionsContainerTarget.insertAdjacentHTML(
      "beforeend",
      newQuestionHtml
    );

    this.sortable.option("onEnd", (evt) => {
      this.updateQuestionOrder(evt);
    });
  }

  get questionCount() {
    return this.questionsContainerTarget.querySelectorAll(
      ".custom-question-div:not(.hidden)"
    ).length;
  }
}
