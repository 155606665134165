import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { answerRating: Number };
  static targets = ["button"];

  connect() {
    this.activeButton = null;
    const buttons = this.element.querySelectorAll(".rating-bx-item");

    buttons.forEach((button) => {
      if (
        this.answerRatingValue != null &&
        parseInt(this.answerRatingValue) === parseInt(button.value)
      ) {
        button.classList.add("active");
        this.activeButton = button;
      }
    });
  }

  click(event) {
    const clickedButton = event.currentTarget;
    this.setActiveButton(clickedButton);
  }

  setActiveButton(newActiveButton) {
    if (this.activeButton) {
      this.activeButton.classList.remove("active");
    }

    this.activeButton = newActiveButton;
    this.activeButton.classList.add("active");
  }
}
