import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sendNow", "sendLater", "sendLaterDate"];

  sendLaterDateChanged() {
    this.sendLaterTarget.checked = !!this.sendLaterDateTarget.value;
  }

  sendNowChanged() {
    this.sendLaterDateTarget.value = null;
    this.sendNowTarget.checked = true;
  }
}
