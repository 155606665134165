import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { user: Number, project: Number };
  static targets = [
    "feedbackModal",
    "text",
    "anonymousToggle",
    "toggleController",
  ];

  connect() {
    this.element.addEventListener("toggle-changed", (event) =>
      this.handleToggleChange(event)
    );
  }

  disconnect() {
    this.element.removeEventListener("toggle-changed", (event) =>
      this.handleToggleChange(event)
    );
  }

  handleToggleChange(event) {
    const { toggled } = event.detail;

    this.updateHiddenInput(toggled);
  }

  showModal(event) {
    event.preventDefault();
    this.feedbackModalTarget.classList.remove("hidden");
  }

  hideModal(event) {
    event.preventDefault();
    this.feedbackModalTarget.classList.add("hidden");
    this.resetValues();
  }

  submitFeedback() {
    const anonymousMode = this.anonymousToggleTarget.value;
    const userId = this.userValue;
    const projectId = this.projectValue;
    const textAnswer = this.textTarget.value;

    const data = {
      user_id: userId,
      project_id: projectId,
      text_answer: textAnswer,
      anonymous_mode: anonymousMode,
    };

    fetch("/individuals/feedbacks", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.feedbackModalTarget.classList.add("hidden");
          this.resetValues();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  resetValues() {
    this.textTarget.value = "";
    if (this.anonymousToggleTarget.value == "0") {
      const otherController =
        this.application.getControllerForElementAndIdentifier(
          this.toggleControllerTarget,
          "toggle"
        );
      otherController.toggle();
    }
    this.anonymousToggleTarget.value = "1";
  }

  updateHiddenInput(toggled) {
    this.anonymousToggleTarget.value = toggled ? "1" : "0";
  }
}
