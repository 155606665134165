import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "button", "context"];

  connect() {
    const buttonType = this.inputTarget;
    if (buttonType.value === "rating") {
      this.buttonTargets[1].click();
    }
  }

  toggle(event) {
    const buttonType = event.currentTarget.dataset.type;
    this.inputTarget.value = buttonType;

    this.buttonTargets.forEach((btn) => {
      if (btn.dataset.type === buttonType) {
        btn.classList.add("bg-[#c7b7d6]");
        btn.classList.remove("bg-white", "border", "border-gray-200");
      } else {
        btn.classList.add("bg-white", "border", "border-gray-200");
        btn.classList.remove("bg-[#c7b7d6]");
      }
    });

    if (this.contextTarget) {
      if (buttonType === "rating") {
        this.contextTarget.classList.remove("hidden");
      } else {
        this.contextTarget.classList.add("hidden");
      }
    }
  }
}

