import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "back", "indicator", "closeDateContext", "closeDateControl", "nextBtn", "submitBtn"];

  connect() {
    this.currentStepIndex = 0;
    this.showCurrentStep();

    this.element.addEventListener("toggle-changed", (event) => this.handleToggleChange(event));
  }

  disconnect() {
    this.element.removeEventListener("toggle-changed", (event) => this.handleToggleChange(event));
  }

  handleToggleChange(event) {
    const { toggled, id } = event.detail;

    if (id === 'close-date-toggle') {
      if (toggled) {
        this.closeDateContextTarget.classList.remove("hidden");
      } else {
        this.closeDateContextTarget.classList.add("hidden");
        this.closeDateControlTarget.value = "";
      }
    }
  }

  next() {
    if (this.currentStepIndex < this.stepTargets.length - 1) {
      this.currentStepIndex++;
      this.showCurrentStep();
      if (this.currentStepIndex === this.stepTargets.length - 1) {
        this.nextBtnTarget.classList.add("hidden");
        this.submitBtnTarget.classList.remove("hidden");
      }
    }

    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
  }

  back() {
    if (this.currentStepIndex > 0) {
      if (this.currentStepIndex === this.stepTargets.length - 1) {
        this.nextBtnTarget.classList.remove("hidden");
        this.submitBtnTarget.classList.add("hidden");
      }

      this.currentStepIndex--;
      this.showCurrentStep();
    }

    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
  }

  showCurrentStep() {
    this.stepTargets.forEach((el, index) => {
      el.classList.toggle("hidden", index !== this.currentStepIndex);
    });
    this.updateStepIndicator();
    this.updateBackButtonVisibility();
  }

  updateStepIndicator() {
    this.indicatorTargets.forEach((li, index) => {
      const button = li.querySelector('button');

      if (index < this.currentStepIndex) {
        button.classList.remove('bg-white');
        button.classList.remove('border-gray-400');
        button.classList.remove('bg-violet-900');
        button.classList.add('bg-purple');
        button.classList.add('border-purple');
      } else if (index === this.currentStepIndex) {
        button.classList.remove('bg-white');
        button.classList.remove('border-gray-400');
        button.classList.add('bg-violet-900');
        button.classList.add('border-purple');
      } else {
        button.classList.remove('border-purple');
        button.classList.remove('bg-purple');
        button.classList.remove('bg-violet-900');
        button.classList.add('bg-white');
        button.classList.add('border-gray-400');
      }
    });
  }

  updateBackButtonVisibility() {
    if (this.currentStepIndex === 0) {
      this.backTarget.classList.add("hidden");
    } else {
      this.backTarget.classList.remove("hidden");
    }
  }
}
