import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "delete", "edit", "close"];

  connect() {
    this.hideMenu();
    this.hideMenuActions();
    document.addEventListener("click", (event) =>
      this.handleDocumentClick(event)
    );
  }

  toggleMenu() {
    this.menuTarget.classList.toggle("hidden");
  }

  hideMenu() {
    this.menuTarget.classList.add("hidden");
  }

  handleDocumentClick(event) {
    if (
      !this.menuTarget.contains(event.target) &&
      !this.element.contains(event.target)
    ) {
      this.hideMenu();
    }
  }

  hideMenuActions() {
    if (this.menuTarget.dataset.menuStatusValue == "live" || this.menuTarget.dataset.menuStatusValue == "complete") {
      this.deleteTarget.classList.add("hidden");
      this.editTarget.classList.add("hidden");
    } else if (this.menuTarget.dataset.menuStatusValue == "closed") {
      this.editTarget.classList.add("hidden");
      this.closeTarget.classList.add("hidden");
    } else {
      this.closeTarget.classList.add("hidden");
    }
  }

  disconnect() {
    document.removeEventListener("click", this.handleDocumentClick);
  }
}
