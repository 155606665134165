import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"];
  static values = { size: String };

  connect() {
    let isToggled = Boolean(
      this.element.dataset.isToggled
        ? this.element.dataset.isToggled === "1"
        : false
    );
    if (isToggled) {
      this.toggle();
    }
  }

  toggle() {
    this.isToggled = !this.isToggled;
    this.updateToggleClass();
    this.emitToggle();
    this.updateHiddenInput();
  }

  updateHiddenInput() {
    const hiddenInput = document.getElementById("toggleValue");
    hiddenInput.value = this.isToggled ? "1" : "0";
  }

  emitToggle() {
    const toggleId = this.element.dataset.toggleId;
    if (toggleId) {
      const event = new CustomEvent("toggle-changed", {
        detail: { toggled: this.isToggled, id: toggleId },
        bubbles: true,
      });
      this.element.dispatchEvent(event);
    }
  }

  updateToggleClass() {
    var toggleClass = this.isToggled ? "translate-x-6" : "translate-x-0";
    this.toggleTarget.classList.remove("translate-x-6", "translate-x-0");
    if (this.sizeValue == "small") {
      toggleClass = this.isToggled ? "translate-x-4" : "translate-x-0";
      this.toggleTarget.classList.remove("translate-x-4", "translate-x-0");
    }
    this.toggleTarget.classList.add(toggleClass);

    const toggleBgClass = this.isToggled ? "bg-purple" : "bg-gray-200";
    this.toggleTarget.parentNode.classList.remove("bg-gray-200", "bg-purple");
    this.toggleTarget.parentNode.classList.add(toggleBgClass);
  }
}
