import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    user: Number,
    feedback: Number,
    feedbackType: String,
    project: Number,
  };
  static targets = [
    "feedbackModal",
    "text",
    "anonymousToggle",
    "toggleController",
    "anonymousOffDescription",
  ];

  connect() {
    this.attachEventListeners();
    this.element.addEventListener("toggle-changed", (event) =>
      this.handleToggleChange(event)
    );
  }

  disconnect() {
    this.element.removeEventListener("toggle-changed", (event) =>
      this.handleToggleChange(event)
    );
  }

  handleToggleChange(event) {
    const { toggled } = event.detail;

    this.updateHiddenInput(toggled);
  }

  showModal(event) {
    event.preventDefault();
    this.feedbackModalTarget.classList.remove("hidden");
  }

  hideModal(event) {
    event.preventDefault();
    this.feedbackModalTarget.classList.add("hidden");
    this.resetValues();
  }

  submitComment() {
    const anonymousMode = this.hasAnonymousToggleTarget
      ? this.anonymousToggleTarget.value
      : false;
    const userId = this.userValue;
    const feedbackId = this.feedbackValue;
    const textAnswer = this.textTarget.value;
    const feedbackType = this.feedbackTypeValue;
    var currentUrl = window.location.pathname;
    var pathSegments = currentUrl.split("/");
    var desiredSegment = pathSegments[1];

    if (desiredSegment == "leaders") {
      desiredSegment = desiredSegment + "/projects/" + this.projectValue;
    }

    const data = {
      user_id: userId,
      feedback_id: feedbackId,
      text_answer: textAnswer,
      anonymous_mode: anonymousMode,
      feedback_type: feedbackType,
    };

    fetch("/" + desiredSegment + "/comments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to create comment");
        }
        return response.text();
      })
      .then((html) => {
        if (this.hasFeedbackModalTarget) {
          this.feedbackModalTarget.classList.add("hidden");
        }
        this.resetValues();
        this.element.innerHTML = html;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  resetValues() {
    this.textTarget.value = "";
    if (this.hasAnonymousToggleTarget) {
      if (this.anonymousToggleTarget.value == "0") {
        const otherController =
          this.application.getControllerForElementAndIdentifier(
            this.toggleControllerTarget,
            "toggle"
          );
        otherController.toggle();
      }
      this.anonymousToggleTarget.value = "1";
    }
  }

  updateHiddenInput(toggled) {
    this.anonymousToggleTarget.value = toggled ? "1" : "0";
    if (toggled) {
      this.anonymousOffDescriptionTarget.classList.add("hidden");
    } else {
      this.anonymousOffDescriptionTarget.classList.remove("hidden");
    }
  }

  attachEventListeners() {
    const tds = this.element.querySelectorAll(".thread-dropdown");
    tds.forEach((td) => {
      td.removeEventListener("click", this.toggleThreadDropdown);
      td.addEventListener("click", this.toggleThreadDropdown);
    });
  }

  toggleThreadDropdown() {
    var dropdown = this;
    $(dropdown.children[1]).toggle();
    $(dropdown.children[2]).toggle();
    var panel = dropdown.nextElementSibling;
    var dropDownTitleElement = dropdown.querySelector(".dropdown-title");
    var tempText;
    if (dropDownTitleElement.innerText === "Open Conversation Thread") {
      tempText = "Close Conversation Thread";
    } else if (dropDownTitleElement.innerText === "Close Conversation Thread") {
      tempText = "Open Conversation Thread";
    }
    if (dropDownTitleElement.innerText === "Add reply") {
      tempText = "Close";
    } else if (dropDownTitleElement.innerText === "Close") {
      tempText = "Add reply";
    }
    dropDownTitleElement.innerText = tempText;
    $(panel).toggle();
  }
}
