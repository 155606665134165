import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { index: Number, user: Number };
  static targets = ["text", "questionDiv"];

  connect() {
    if (this.hasTextTarget) {
      this.textTarget.addEventListener("input", () => {
        this.submitText();
      });
    }
  }

  submit(event) {
    const miniPulseQuestion = this.indexValue;
    const userMiniPulse = this.userValue;
    const rating = event.target.value;

    const data = {
      mini_pulse_question: miniPulseQuestion,
      user_mini_pulse: userMiniPulse,
      rating,
      rating_question: true,
    };

    fetch("/user_mini_pulse_answers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          this.dispatch("submit", { detail: { div: this.questionDivTarget } });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  submitText() {
    const miniPulseQuestion = this.indexValue;
    const userMiniPulse = this.userValue;
    const textAnswer = this.textTarget.value;

    const data = {
      mini_pulse_question: miniPulseQuestion,
      user_mini_pulse: userMiniPulse,
      text_answer: textAnswer,
      rating_question: false,
    };

    fetch("/user_mini_pulse_answers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          const pageController =
            this.application.getControllerForElementAndIdentifier(
              document.getElementById("page"),
              "page"
            );

          pageController.track({ detail: { div: this.questionDivTarget } });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
