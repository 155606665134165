import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { index: Number, user: Number, project: Number };
  static targets = ["text", "questionDiv"];

  connect() {
    if (this.hasTextTarget) {
      this.textTarget.addEventListener("input", () => {
        this.submitStandardPulseText();
      });
    }
  }

  submitStandardPulseText() {
    const questionId = this.indexValue;
    const projectId = this.projectValue;
    const userId = this.userValue;
    const textAnswer = this.textTarget.value;

    const data = {
      question_id: questionId,
      project_id: projectId,
      user_id: userId,
      text_answer: textAnswer,
    };

    fetch("/create_text_answer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          const pageController =
            this.application.getControllerForElementAndIdentifier(
              document.getElementById("page"),
              "page"
            );

          pageController.track({ detail: { div: this.questionDivTarget } });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
