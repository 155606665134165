import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "question",
    "questionCountDiv",
    "sendNow",
    "sendLaterDate",
    "sendDateDiv",
    "additionalQuestionDiv",
    "customQuestionToggle",
    "customQuestionsContainer",
    "completionTimeDiv",
    "expireDate",
    "expireDateDiv",
  ];

  connect() {
    this.questionCountDivTarget.textContent = this.questionTargets.length;
  }

  trigger() {
    if (this.sendNowTarget.checked == true) {
      this.sendDateDivTarget.textContent = new Date().toLocaleDateString(
        "en-GB",
        { year: "numeric", month: "long", day: "numeric" }
      );
    } else {
      this.sendDateDivTarget.textContent = new Date(
        this.sendLaterDateTarget.value
      ).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    this.additionalQuestionDivTarget.textContent =
      this.customQuestionToggleTarget.value == 1
        ? this.customQuestionsContainerTarget.querySelectorAll(
            ".custom-question-div:not(.hidden)"
          ).length
        : " no ";

    this.completionTimeDivTarget.textContent = this.calculateTime;
    if (this.expireDateTarget.value != "") {
      this.expireDateDivTarget.textContent = new Date(
        this.expireDateTarget.value
      ).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      this.expireDateDivTarget.textContent = "N/A";
    }
  }

  get calculateTime() {
    const questionCount =
      this.questionTargets.length +
      this.customQuestionsContainerTarget.querySelectorAll(
        ".custom-question-div"
      ).length;
    const time = questionCount * 15;

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const minutesString =
      minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "";
    const secondsString =
      seconds > 0 ? `${seconds} second${seconds > 1 ? "s" : ""}` : "";

    const timeString = [minutesString, secondsString].filter(Boolean).join(" ");

    return timeString;
  }
}
